import { Component } from 'react';
import * as Sentry from '@sentry/react';
import {Box, Typography, Button, Link} from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error';

class MuiErrorBoundarySimple extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    // Display fallback UI
    this.setState({ hasError: true });
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      if (this.props.isInnerErrorBoundary) {
        scope.setExtra("inInnerErrorBoundary", true)
        scope.setLevel('fatal');
      } else {
        scope.setExtra("inOuterErrorBoundary", true)
        scope.setLevel('fatal');
      }
      if(this.props.componentName){
        Sentry.setTag('RealScoutComponentName', this.props.componentName)
      }
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box textAlign="center" p={3}>
          <Box mb={2}>
            <ErrorIcon sx={{fontSize: 40}}/>
          </Box>
          <Typography variant="h5" mb={1}>Sorry, something has gone wrong.</Typography>
          <Typography variant="body2" mb={2}>
            The details have been reported to our team. Please&nbsp;
            <Link href={this.props.isAgentErrorBoundary ? "mailto:support@realscout.com?subject=Issue using agent experience" : "mailto:support@realscout.com?subject=Issue using homebuyer experience"}>
              contact us
            </Link>
            &nbsp;if you continue to experience this issue.
          </Typography>
          <Button size="small" variant="outlined" onClick={() => {window.location.reload()}}>
            Refresh
          </Button>
        </Box>
      );
    }
    return this.props.children;
  }
}

export default MuiErrorBoundarySimple;
